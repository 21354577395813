import { createI18n } from 'vue-i18n'
import en from "./locales/en.json"
import pl from "./locales/pl.json"

const messages = { en, pl }

const defineDefaultLocale = () => {
  const localStorageLang = localStorage.getItem('locale')
  if (localStorageLang) {
    return localStorageLang
  }
  
  return "pl"
}
defineDefaultLocale()

export default createI18n({
  legacy: false,
  locale: defineDefaultLocale(),
  fallbackLocale: 'pl',
  messages,
})

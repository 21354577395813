<template>
  <div class="cookie-setting-root">
    <div class="cookie-header">
      <h3>Dostosuj ciasteczka (cookies) na własny gust ;)</h3>
      <p>
        Nie chcemy zmuszać nikogo do klikania w te okienko, ale prawo nakazuje
      </p>
      <div
        v-if="!isShowMoreActive.value"
        @click="isShowMoreActive.value = true"
      >
        <b>Pokaż więcej</b>
      </div>
      <div
        v-if="isShowMoreActive.value"
        @click="isShowMoreActive.value = false"
      >
        <b>Ukryj</b>
      </div>
      <p v-if="isShowMoreActive.value">
        Używamy plików cookie do zapewnienia prawidłowego działania strony i
        wykonywania określonych funkcji. Niezbędne pliki cookie są wymagane,
        natomiast pliki innych firm, które analizują korzystanie ze strony i
        personalizują treści, są używane za Twoją zgodą. Możesz zarządzać zgodą
        na te pliki, choć ich wyłączenie może wpłynąć na działanie strony.
      </p>
    </div>
    <div class="cookie-list">
      <CookieElement
        v-for="cookie in cookies.value"
        :key="`cookie_${cookie.name}`"
        :name="cookie.name"
        :description="cookie.description"
        :is-required="cookie.isRequired"
        :items="cookie.items"
      />
    </div>

    <div class="cookie-footer">
      <button class="login-btn account-btn" @click="emit('close')">
        Odrzuć wszystkie
      </button>
      <button class="login-btn account-btn" @click="emit('close')">
        Zapisz moje preferencje
      </button>
      <button class="register-btn account-btn" @click="emit('close')">
        Akceptuj wszystkie
      </button>
    </div>
  </div>
</template>

<script setup>
import { reactive, defineEmits } from "vue";

import { useI18n } from "vue-i18n";

import CookieElement from "./CookieElement.vue";

const emit = defineEmits(["close"]);

const { t } = useI18n();

const isShowMoreActive = reactive({
  value: false,
});

const cookies = reactive({
  value: [
    {
      name: t("cookies.required.name"),
      description: t("cookies.required.description"),
      isRequired: true,
      items: [
        {
          name: "csrftoken",
          description: t("cookies.required.csrf.description"),
          duration: t("cookies.required.csrf.duration"),
        },
        {
          name: "sessionid",
          description: t("cookies.required.sessionid.description"),
          duration: t("cookies.required.sessionid.duration"),
        },
        {
          name: "_GRECAPTCHA",
          description: t("cookies.required.GRECAPTCHA.description"),
          duration: t("cookies.required.GRECAPTCHA.duration"),
        },
      ],
    },
  ],
});
</script>

<style scoped lang="scss" src="@/components/scss/cookie-setting-modal.scss" />

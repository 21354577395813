<script setup>
import { useI18n } from "vue-i18n";
import { useModalsStore } from "@/stores/modals_store";

const modalsStore = useModalsStore();

const { t } = useI18n();
</script>

<template>
  <div class="footer">
    <div class="body-inner">
      <div class="copitight">{{ t("footer.copy") }}</div>
      <div class="footer-links">
        <div
          class="pseudo-btn"
          @click="modalsStore.isCookieSettingActive.value = true"
        >
          {{ t("footer.cookie") }}
        </div>
        <a href="/polityka-prywatnosci">{{ t("footer.privacy") }}</a>
        <a href="/regulamin">{{ t("footer.regulamin") }}</a>
      </div>
      <div class="clr"></div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@media screen and (max-width: 991.98px) {
  .footer {
    padding: 20px;
    box-sizing: border-box;

    .body-inner {
      width: 100%;
    }

    .footer-links,
    .copitight {
      float: none;
      text-align: center;
      font-size: 14px;
      font-style: normal;
    }

    .copitight {
      margin-bottom: 10px;
    }
  }
}
</style>

export const mainRoutes = [
  {
    id: 1,
    path: "/",
    name: "home",
  },
  {
    id: 2,
    path: "/contact",
    name: "contact",
  },
];

<script setup>
import { mainRoutes } from "../router/routes";
import { defineProps, defineEmits } from "vue";
import MobileMenu from "./MobileMenu.vue"

const emit = defineEmits(["menu:open", "menu:close"]);
const props = defineProps({
  showMenu: { type: Boolean, default: false },
});

const openMenu = () => {
  emit("menu:open");
};

const closeMenu = () => {
  emit("menu:close");
};

const toggleMenu = () => {
  if (props.showMenu) {
    closeMenu();
  } else {
    openMenu();
  }
};

const makeRequest = (navigate) => {
  closeMenu();
  navigate();
};
</script>

<template>
<header>
  <div class="mobile-header">
    <div class="mobile-header__grid">
      <div class="mobile-header__logo">
        <RouterLink to="/" custom v-slot="{ href, navigate }">
          <a @click="makeRequest(navigate)" :href="href">
            <img src="../assets/images/logo.png" alt="Budzet Rodzinki" />
          </a>
        </RouterLink>
      </div>
      <div class="mobile-header__actions">
        <a
          href="/login"
          class="mobile-header__btn mobile-header__btn--outline"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="21"
            height="21"
            viewBox="0 0 21 21"
            fill="none"
          >
            <path
              d="M8.75 9.625H3.5V2.625C3.5 2.14175 3.89175 1.75 4.375 1.75H16.625C17.1083 1.75 17.5 2.14175 17.5 2.625V18.375C17.5 18.8583 17.1083 19.25 16.625 19.25H4.375C3.89175 19.25 3.5 18.8583 3.5 18.375V11.375H8.75V14L13.125 10.5L8.75 7V9.625Z"
              fill="#3D3734"
            />
          </svg>
        </a>
        <a
          href="/registration"
          class="mobile-header__btn mobile-header__btn--primary"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="21"
            height="21"
            viewBox="0 0 21 21"
            fill="none"
          >
            <path
              d="M11.4211 13.3589V20H3C3 16.2129 6.01619 13.1429 9.73684 13.1429C10.3184 13.1429 10.8828 13.2179 11.4211 13.3589ZM9.73684 12.2857C6.94526 12.2857 4.68421 9.98429 4.68421 7.14286C4.68421 4.30143 6.94526 2 9.73684 2C12.5284 2 14.7895 4.30143 14.7895 7.14286C14.7895 9.98429 12.5284 12.2857 9.73684 12.2857ZM14.7895 15.7143V13.1429H16.4737V15.7143H19V17.4286H16.4737V20H14.7895V17.4286H12.2632V15.7143H14.7895Z"
              fill="white"
            />
          </svg>
        </a>
        <div class="mobile-header__menu-btn" @click.prevent="toggleMenu">
          <svg
            v-if="!showMenu"
            xmlns="http://www.w3.org/2000/svg"
            width="30"
            height="17"
            viewBox="0 0 30 17"
            fill="none"
          >
            <rect width="30" height="1" rx="0.5" fill="#3D3734" />
            <rect y="8" width="30" height="1" rx="0.5" fill="#3D3734" />
            <rect y="16" width="30" height="1" rx="0.5" fill="#3D3734" />
          </svg>
          <svg
            v-else
            xmlns="http://www.w3.org/2000/svg"
            width="17"
            height="17"
            viewBox="0 0 17 17"
            fill="none"
          >
            <rect
              x="0.259766"
              y="15.8347"
              width="22.0265"
              height="0.734216"
              rx="0.367108"
              transform="rotate(-45 0.259766 15.8347)"
              fill="#3D3734"
            />
            <rect
              x="15.5752"
              y="16.0942"
              width="22.0265"
              height="0.734216"
              rx="0.367108"
              transform="rotate(-135 15.5752 16.0942)"
              fill="#3D3734"
            />
          </svg>
        </div>
      </div>
    </div>
  </div>
  <MobileMenu :show="showMenu"  @menu:navigate="closeMenu" :routes="mainRoutes" />
</header>
</template>

<style lang="scss" scoped>
.mobile-header {
  &__grid {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  &__logo {
    flex: 0 0 115px;

    a {
      display: flex;
      align-items: center;
    }
  }

  &__actions {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  &__menu-btn {
    width: 40px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  &__btn {
    border-radius: 40px;
    border: 1px solid #f98347;
    width: 45px;
    height: 45px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    
    &--primary {
      background-color: #F98347;
    }
  }
}
</style>

<template>
  <div id="app">
    <LocalModal
      :is-active="modalsStore.isCookieSettingActive.value"
      @closeClicked="modalsStore.isCookieSettingActive.value = false"
    >
      <template v-slot:content>
        <CookieSettingModal
          @close="modalsStore.isCookieSettingActive.value = false"
        />
      </template>
    </LocalModal>
    <BudzetHeader :scroll="scrolledPixels" />
    <MobileHeader
      class="mobile-header"
      :show-menu="showMenu"
      @menu:open="openMobileMenu"
      @menu:close="closeMobileMenu"
    />
    <main>
      <RouterView v-slot="{ Component }">
        <Transition name="fade" mode="out-in">
          <component :is="Component" />
        </Transition>
      </RouterView>
    </main>

    <BudzetFooter />
  </div>
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount } from "vue";
import BudzetHeader from "./components/BudzetHeader.vue";
import MobileHeader from "./components/MobileHeader.vue";
import BudzetFooter from "./components/BudzetFooter.vue";
import LocalModal from "./components/LocalModal.vue";
import { useModalsStore } from "./stores/modals_store";
import CookieSettingModal from "./components/CookieSettingModal.vue";

const modalsStore = useModalsStore();

const scrolledPixels = ref(0);
const showMenu = ref(false);
const updateScrolledPixels = () => {
  scrolledPixels.value = window.scrollY || window.pageYOffset;
};

const openMobileMenu = () => {
  showMenu.value = true;
};
const closeMobileMenu = () => {
  showMenu.value = false;
};

onMounted(() => {
  updateScrolledPixels();
  window.addEventListener("scroll", updateScrolledPixels);
  window.addEventListener("resize", closeMobileMenu);
});

onBeforeUnmount(() => {
  window.removeEventListener("scroll", updateScrolledPixels);
  window.removeEventListener("resize", closeMobileMenu);
});
</script>

<style lang="scss">
@import "assets/css/stylesheet.css";
@import "assets/css/animate.css";

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.25s ease-out;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.main-header {
  display: none;
}

.mobile-header {
  box-shadow: 0px 4px 16px 0px rgba(61, 55, 52, 0.08),
    0px 2px 4px 0px rgba(61, 55, 52, 0.04),
    0px 0px 2px 0px rgba(61, 55, 52, 0.16);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 20;
  background-color: #fff;
  padding: 10px 20px;
  box-sizing: border-box;
}

@media screen and (min-width: 992px) {
  .main-header {
    display: block;
  }

  .mobile-header {
    display: none;
  }
}
</style>

import { defineStore } from "pinia";
import { reactive } from "vue";

export const useModalsStore = defineStore(
  "modals-store",
  () => {
    const isCookieSettingActive = reactive({
      value: true,
    });

    return { isCookieSettingActive };
  },
  { persist: true }
);

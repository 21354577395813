<script setup>
import { useI18n } from "vue-i18n";
import { defineProps, watch, ref, onMounted } from "vue";
import { mainRoutes } from "../router/routes";

const { t, locale } = useI18n();

const props = defineProps({
  scroll: { type: Number, default: 0 },
});

const showLocaleDropdown = ref(false)
const selectedLocale = ref({
  id: 2,
  value: "pl",
  label: "PL",
});

const langOptions = ref([
  {
    id: 2,
    value: "pl",
    label: "PL",
  },
]);

const closeLocaleDropdown = () => {
  showLocaleDropdown.value = false
}

const openLocaleDropdown = () => {
  showLocaleDropdown.value = true
}

const toggleLocaleDropdown = () => {
  if (showLocaleDropdown.value) {
    closeLocaleDropdown()
  } else {
    openLocaleDropdown()
  }
}

const changeLocale = lang => {
  selectedLocale.value = lang
  localStorage.setItem("locale", lang.value)
  closeLocaleDropdown()
}

watch(
  () => props.scroll,
  (val) => {
    if (val > 93) {
      document.body.classList.add("fixed");
    } else {
      document.body.classList.remove("fixed");
    }
  }
);

watch(
  () => selectedLocale.value,
  (val) => {
    locale.value = val.value;
  }
);

onMounted(() => {
   const localStorageLang = localStorage.getItem('locale')
   if (localStorageLang) {
    selectedLocale.value = [...langOptions.value].find(lng => lng.value === localStorageLang)
   }
})
</script>

<template>
  <div class="header main-header">
    <div class="body-inner">
      <RouterLink to="/" custom v-slot="{ href, navigate }">
        <a @click="navigate" :href="href" class="logo">
          <img src="../assets/images/logo.png" alt="Budzet Rodzinki" />
        </a>
      </RouterLink>
      <div class="language">
        <span class="lang-icon"
          ><img src="../assets/images/lang.svg" alt="arrow"
        /></span>
        <div class="language__selector" >
          <div class="language__selected" @click.prevent="toggleLocaleDropdown">{{ selectedLocale.label }}</div>
          <div
            class="language__options"
            v-if="langOptions.length && showLocaleDropdown"
          >
            <div
              :class="['language__option', { active: selectedLocale.id === lang.id }]"
              v-for="lang in langOptions"
              :key="lang.id"
              @click.prevent="changeLocale(lang)"
            >
              {{ lang.label }}
            </div>
          </div>
        </div>
      </div>
      <div class="account">
        <a href="/login" class="login-btn account-btn"
          >{{  t("menu.login") }}</a
        >
        <a
          href="/registration"
          class="register-btn account-btn"
          >{{  t("menu.registration") }}</a
        >
      </div>
      <div class="main-menu" v-if="mainRoutes.length">
        <ul>
          <RouterLink
            v-for="route in mainRoutes"
            :key="route.id"
            :to="route.path"
            custom
            v-slot="{ href, navigate, isActive }"
          >
            <li :class="[{ active: isActive }]">
              <a :href="href" @click="navigate" class="scroll-link">{{
                t(`menu.${route.name}`)
              }}</a>
            </li>
          </RouterLink>
        </ul>
      </div>
      <div class="clr"></div>
    </div>
  </div>
  <div class="header-bg">
    <div class="body-inner">
      <div class="heder-bgimg">
        <div class="header-stars">
          <div class="star-1 star">
            <img src="../assets/images/star_1.svg" alt="top" />
          </div>
          <div class="star-2 star">
            <img src="../assets/images/star_1.svg" alt="top" />
          </div>
          <div class="star-3 star">
            <img src="../assets/images/star_1.svg" alt="top" />
          </div>
        </div>
        <div class="header-line amimate-line">
          <img src="../assets/images/header_line.svg" alt="top" />
        </div>
        <div class="header-image">
          <img src="../assets/images/top.svg" alt="top" />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.language {
  &__selector {
   position: relative; 
  }
  
  &__options {
    position: absolute;
    top: 100%;
    left: 0;
    background-color: white;
    box-shadow: 0 0 7px rgba(183, 192, 206, 0.4);
  }
  
  &__option {
    background-color: #fff;
    color: #3D3734;
    padding: 6px;
    font-size: 14px;
    width: 43px;
    box-sizing: border-box;
    line-height: 17px;
    transition: 0.2s ease-in-out;
    cursor: pointer;
    
    &.active,
    &:hover {
      background-color: #fee6ce;
      
      & ~ .active {
        background-color: #fff;
      }
    }
  }
  
  &__selected {
    color: #444;
    line-height: 28px;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    padding: 0 5px;
    cursor: pointer;
  }
}
</style>

<template>
  <div class="cookie-element-wrap">
    <i
      class="icon icon-arrow"
      :class="{ 'icon-arrow-right': !isDropdownActive.value }"
      @click="isDropdownActive.value = !isDropdownActive.value"
    ></i>
    <div class="cookie-element">
      <div class="cookie-element-header">
        <div>
          <b>{{ name }}</b>
        </div>
        <CustomToggle
          :is-disabled="props.isRequired"
          :initial-value="props.isRequired"
        />
      </div>
      <div class="cookie-element-desciption">
        <p>{{ description }}</p>
      </div>
      <div class="cookie-element-dropdown" v-if="isDropdownActive.value">
        <CookieItem
          v-for="item in items"
          :key="`cookie_item_${item.name}`"
          :name="item.name"
          :description="item.description"
          :duration="item.duration"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { reactive, defineProps } from "vue";

import CookieItem from "./CookieItem.vue";
import CustomToggle from "./CustomToggle.vue";

const props = defineProps({
  name: {
    required: true,
  },
  description: {
    required: true,
  },
  isRequired: {
    required: true,
  },
  items: {
    required: true,
  },
});

const isDropdownActive = reactive({
  value: false,
});
</script>

<style scoped lang="scss" src="@/components/scss/cookie-setting-modal.scss" />

import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/:catchAll(.*)",
    redirect: { name: "home" },
  },
  {
    name: "home",
    path: "/",
    component: () => import("../pages/HomePage.vue"),
  },
  {
    name: "contact",
    path: "/contact",
    component: () => import("../pages/ContactPage.vue"),
  },
  {
    name: "PrivacyPolicy",
    path: "/polityka-prywatnosci",
    component: () => import("../pages/PrivacyPolicy.vue"),
  },
  {
    name: "Regulamin",
    path: "/regulamin",
    component: () => import("../pages/RegulaminPage.vue"),
  },
  {
    name: "Login",
    path: "/login",
    component: () => import("../pages/LoginPage.vue"),
  },
  {
    name: "Register",
    path: "/registration",
    component: () => import("../pages/RegisterPage.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(),
  scrollBehavior() {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve({ top: 0 });
      }, 250);
    });
  },
  routes,
});

export default router;

<script setup>
import { defineProps, defineEmits, ref, watch, onMounted } from "vue";
import { useI18n } from "vue-i18n";

const { t, locale } = useI18n();

const emit = defineEmits(["menu:navigate"]);

defineProps({
  show: { type: Boolean, default: false },
  routes: { type: Array, default: () => [] },
});

const showLocaleDropdown = ref(false);
const selectedLocale = ref({
  id: 2,
  value: "pl",
  label: "PL",
});

const langOptions = ref([
  {
    id: 1,
    value: "en",
    label: "EN",
  },
  {
    id: 2,
    value: "pl",
    label: "PL",
  },
]);

const closeLocaleDropdown = () => {
  showLocaleDropdown.value = false;
};

const openLocaleDropdown = () => {
  showLocaleDropdown.value = true;
};

const toggleLocaleDropdown = () => {
  if (showLocaleDropdown.value) {
    closeLocaleDropdown();
  } else {
    openLocaleDropdown();
  }
};

const changeLocale = (lang) => {
  selectedLocale.value = lang;
  localStorage.setItem("locale", lang.value);
  closeLocaleDropdown();
};

watch(
  () => selectedLocale.value,
  (val) => {
    locale.value = val.value;
  }
);

onMounted(() => {
  const localStorageLang = localStorage.getItem("locale");
  if (localStorageLang) {
    selectedLocale.value = [...langOptions.value].find(
      (lng) => lng.value === localStorageLang
    );
  }
});

const navigateMenu = (navigate) => {
  emit("menu:navigate");
  navigate();
};
</script>

<template>
  <Transition name="mobile-menu">
    <div class="mobile-menu" v-if="show">
      <div class="mobile-menu__body">
        <ul class="mobile-menu__menu" v-if="routes.length">
          <li
            class="mobile-menu__menu-item"
            v-for="route in routes"
            :key="route.id"
          >
            <RouterLink
              :to="route.path"
              custom
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <a
                :class="[
                  'mobile-menu__menu-link',
                  { 'mobile-menu__menu-link--active': isActive },
                  { 'mobile-menu__menu-link--exact-active': isExactActive },
                ]"
                :href="href"
                @click.prevent="navigateMenu(navigate, isActive)"
              >
                <div class="mobile-menu__menu-separator"></div>
                {{ t(`menu.${route.name}`) }}
              </a>
            </RouterLink>
          </li>
        </ul>
      </div>
      <div class="mobile-menu__footer">
        <div class="mobile-menu__language">
          <span class="mobile-menu__language-icon">
            <img src="../assets/images/lang.svg" alt="arrow" />
          </span>
          <div class="mobile-menu__language-selector">
            <div
              class="mobile-menu__language-selected"
              @click.prevent="toggleLocaleDropdown"
            >
              {{ selectedLocale.label }}
            </div>
            <div
              class="mobile-menu__language-options"
              v-if="langOptions.length && showLocaleDropdown"
            >
              <div
                :class="[
                  'mobile-menu__language-option',
                  { active: selectedLocale.id === lang.id },
                ]"
                v-for="lang in langOptions"
                :key="lang.id"
                @click.prevent="changeLocale(lang)"
              >
                {{ lang.label }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>

<style lang="scss" scoped>
.mobile-menu {
  position: fixed;
  top: 61px;
  left: 0;
  width: 100%;
  height: calc(100% - 61px);
  background: #fff;
  z-index: 10;
  padding: 30px 0;
  display: flex;
  flex-direction: column;
  gap: 20px;
  box-sizing: border-box;

  &__footer {
    padding: 0 20px;
  }

  &__body {
    flex: 1;
  }

  &__menu {
    display: grid;
    grid-gap: 10px;
    
    &-separator {
      display: flex;
      width: 30px;
      height: 2px;
      background: transparent;
    }

    &-link {
      display: flex;
      align-items: center;
      gap: 20px;
      padding: 10px 20px;
      text-decoration: none;
      font-style: normal;
      font-weight: 500;
      position: relative;
      color: rgb(61 55 52 / 50%);
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;

      &:hover {
        color: rgb(61 55 52 / 75%);
      }

      &--active {
        color: rgb(61 55 52 / 100%);

        .mobile-menu__menu-separator {
          background-color: #f98347;
        }
      }
    }

    
  }

  &__call {
    padding: 10px 20px;
    color: #141617;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.49px;
    text-transform: uppercase;
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    align-items: center;
    text-decoration: none;
  }

  &-enter-active,
  &-leave-active {
    transition: all 0.2s ease-in-out 0s;
    -webkit-transition: all 0.2s ease-in-out 0s;
  }

  &-enter-from,
  &-leave-to {
    opacity: 0;
    top: 0;
  }

  &__language {
    display: flex;
    align-items: center;
    gap: 10px;

    &-icon {
      display: flex;
      align-items: center;
    }

    &-selected {
      color: #3d3734;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      cursor: pointer;
    }

    &-selector {
      position: relative;
      padding: 5px 0;
    }

    &-options {
      position: absolute;
      bottom: 100%;
      left: 0;
      background-color: white;
      box-shadow: 0 0 7px rgba(183, 192, 206, 0.4);
    }

    &-option {
      background-color: #fff;
      color: #3d3734;
      padding: 6px;
      font-size: 14px;
      width: 43px;
      box-sizing: border-box;
      line-height: 17px;
      transition: 0.2s ease-in-out;
      cursor: pointer;

      &.active,
      &:hover {
        background-color: #fee6ce;

        & ~ .active {
          background-color: #fff;
        }
      }
    }
  }
}
</style>

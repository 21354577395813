<template>
  <div class="container">
    <label class="switch" :for="`checkbox_${uniqueID.value}`">
      <input
        type="checkbox"
        :id="`checkbox_${uniqueID.value}`"
        v-model="checkbox.value"
        :disabled="props.isDisabled"
      />
      <div class="slider round"></div>
    </label>
  </div>
</template>
<script setup>
import { reactive, defineProps } from "vue";

const props = defineProps({
  isDisabled: {
    required: false,
  },
  initialValue: {
    required: false,
  },
});

const uniqueID = reactive({
  value: Math.random(),
});

const checkbox = reactive({
  value: props.initialValue ? props.initialValue : false,
});
</script>

<style scoped lang="scss" src="@/components/scss/custom-toggle.scss" />
